<template>
  <div class="container">
    <sermon-grid :title="title" :limit="12"></sermon-grid>
  </div>
</template>

<script>
  // import { mapGetters } from 'vuex'
  import SermonGrid from '~/components/SermonGrid'

  export default {
    layout: 'default',
    metaInfo () {
      return { title: this.$t('home') } 
    },
    components: {
      'sermon-grid': SermonGrid
    },
    data: () => ({
      title: window.config.appName
    })
  }
</script>